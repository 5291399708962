<template>
<div style="background-color: var(--wsWHITE)" class="pb-16">

  <!-- Intro -->
  <v-sheet :color="wsACCENT">
    <portal-target v-if="SM" name="market-course-image">
    </portal-target>
    <div style="width: 100%; max-width: 1172px" class="mx-auto pt-4  px-4">
      <!-- Breadcrumbs -->
<!--      <h5 class="wsWHITE">Breadcrumbs</h5>-->

      <!-- Course info -->
      <div class="d-flex align-end justify-space-between" :class="[{'mt-6' : !SM}]">
        <div class="mr-6 pb-6" >
          <h1 class=" wsWHITE" :style="`font-size: ${!SM ? 42 : 24}px`">{{ entity.name }}</h1>
          <h4 class="mt-5 wsWHITE font-weight-regular">{{ entity.short_description }}</h4>
        </div>
        <portal  to="market-course-image" :disabled="!SM">
          <v-img
              :class="[{wsRoundedHalfTop: !SM}]"
              :src="entity.img"
              :width="!SM ? 375 : null"
              :max-width="!SM ? 375 : null"
              height="288"
          />
        </portal>

      </div>
    </div>

    <portal-target v-if="SM" name="market-course-params"></portal-target>

  </v-sheet>



  <!-- Main Content -->
  <div style="width: 100%; max-width: 1172px; position: relative " class="mx-auto px-4 d-flex flex-row justify-space-between">

    <!-- Main Content-->
    <div class="mr-6 flex-grow-1" >

      <!-- Course Full Description -->
      <div v-if="entity.description && entity.description.length" style="width: 100%; max-width: 740px;">
        <h2 class="wsDARKER mb-2"
            :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
            :style="`font-size: ${!SM ? 24 : 19}px`"
        >
          {{  $t('courseDescription')  }}
        </h2>
        <ws-text-viewer
            :value="entity.description"
            :html="entity.description_text"
            isPublic
            class="mt-3"
            :video-height="!SM ? 400 : 250"
        />

      </div>

      <!-- Features -->
      <div v-if="entity.features && entity.features.length">

        <h2 class="wsDARKER mb-2"
            :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
            :style="`font-size: ${!SM ? 24 : 19}px`"
        >
          {{  $t('market.course.features')  }}
        </h2>

        <v-sheet :color="wsLIGHTCARD" class="wsRoundedHalf mt-2 pa-5">
          <v-row>
            <v-col
                v-for="(item,i) in entity.features" :key="i"
                cols="12" md="6"
                :class="[{'mb-4' : i < entity.features.length - 2 && !SM}]"
            >
              <div class="d-flex ">
                <div class="d-flex">
                  <v-icon :color="wsACCENT" class="mr-2 mb-auto ">mdi-check</v-icon>
                  <h5 class="font-weight-regular wsACCENT ">
                    {{ item.text }}
                  </h5>
                </div>

              </div>

            </v-col>
          </v-row>

        </v-sheet>

      </div>

      <!-- Course Content -->
      <div style="position: relative">
        <market-course-content
            :entity="entity"
        />

      </div>

      <!-- Business Info -->
      <div >
        <h2 class="wsDARKER mb-2"
            :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
            :style="`font-size: ${!SM ? 24 : 19}px`"
        >
          {{  $t('market.course.school')  }}
        </h2>
        <v-sheet :color="wsLIGHTCARD" class="pa-4 wsRoundedLight" :class="[{'d-flex' : !SM}]">

          <v-img width="170" max-width="170" height="170" :src="business.img" class="wsRoundedLight mr-6" />

          <div>
            <h2 class="wsDARKER"
                :style="`font-size: ${!SM ? 24 : 19}px`"
                :class="[{'mt-6' : SM}]"
            >
              {{ business.name }}
            </h2>
            <h4 :style="`font-size: ${!SM ? 16 : 13}px`"
                :class="[{'mt-6' : !SM},{'mt-2' : !SM}]"
                class="wsDARKER font-weight-regular " >
              {{ business.description }}
            </h4>

          </div>

        </v-sheet>

      </div>



    </div>

    <!-- Course Params-->
    <portal to="market-course-params" :disabled="!SM">
      <div>
        <v-sheet
            :class="[{wsRoundedHalfBottom : !SM} , {'pa-6' : !SM} , {'pa-4' : SM}]"

            :color="!SM ? wsBACKGROUND : wsACCENT"
            :width="!SM ? '375px' : null"
            :min-width="!SM ? '375px' : null"
            :dark="SM"
        >
          <h1 :class="!SM ? wsDARKER : wsWHITE" :style="`font-size: ${!SM ? 34 : 24}px`"> {{ discountPice }}</h1>
          <h4 v-if="entity.discount" class="font-weight-regular" :class="!SM ? wsDARKER : wsWHITE">
            <span style="text-decoration: line-through">{{ coursePrice }}</span>
          </h4>

          <view-button-course-enroll
              :display="triggerDisplay"
              :entity="entity"
              no-portal
              avalon
          >
            <ws-button
                @click="triggerDisplay++"
                block
                class=" mt-6"
            >
              <v-icon v-if="entity.payed"  class="mr-2">mdi-basket-plus</v-icon>
              <span v-if="entity.payed">{{ $t('Buy') }}</span>
              <span v-if="!entity.payed">{{ $t('Enroll') }}</span>
            </ws-button>
          </view-button-course-enroll>


          <!-- Has Certificates-->
          <div v-if="entity.has_certificates" class="d-flex align-center mt-6">
            <v-icon :color="!SM ? wsACCENT : wsLIGHTCARD" class="mr-2">mdi-shield-check</v-icon>
            <h5 :class="!SM ? wsACCENT : wsLIGHTCARD">
              {{ $t('market.course.has_certificates') }}
            </h5>
          </div>


          <!-- Language-->
          <div class="d-flex align-center " :class="[{'pb-6' : SM} , {'mt-6' : !SM} , {'mt-4' : SM}]">
            <v-icon :color="!SM ? wsACCENT : wsLIGHTCARD" class="mr-2">mdi-web</v-icon>
            <h5 :class="!SM ? wsACCENT : wsLIGHTCARD">{{ languages }}</h5>
          </div>


        </v-sheet>
      </div>
    </portal>



  </div>



</div>
</template>

<script>
import {mapActions} from "vuex";
import languagesList from "@/mixins/languagesList";
import marketCourseContent from "@/components/AvalonEditor/defaultSite/Course/marketCourseContent.vue";
import viewButtonCourseEnroll from "@/components/AvalonEditor/View/elements/button/viewButtonCourseEnroll.vue";
export default {
  name: "MarketCourse",
  mixins : [languagesList],
  components : {
    viewButtonCourseEnroll,
    marketCourseContent
  },
  props : {
    courseUuid : {
      type : String
    },
    course : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      entity : {},
      business : {},
      preview : false,
      triggerDisplay : 0
    }
  },
  computed : {
    languages() {
      if ( this.entity.langs && this.entity.langs.length ) {
        let text = ''

        this.entity.langs.forEach((lang,i) => {
          text += `${this.LANGUAGE_TEXT(lang)}${i < this.entity.langs.length - 1 ? `, ` : ``}`
        })

        return text
      }
      return this.LANGUAGE_TEXT(this.entity.lang)
    },

    discountPice() {

      if ( !this.entity ) {
        return
      }
      if ( !this.entity.payed ) {
        return this.$t('Free')
      }

      let price = (this.entity.has_payment_plans ? this.entity.min_price : this.entity.price)
          - Math.round((this.entity.discount || 0)/100*(this.entity.has_payment_plans ? this.entity.min_price : this.entity.price))

      let text = price + ' ' + this.BUSINESS_CURRENCY
      if ( this.entity.has_payment_plans ) {
        text =  this.$t('priceFrom') + ' ' + text
      }
      return text
    },
    coursePrice() {

      if ( !this.entity ) {
        return
      }
      if ( !this.entity.payed ) {
        return this.$t('Free')
      }

      let price = (this.entity.has_payment_plans ? this.entity.min_price : this.entity.price)

      let text = price + ' ' + this.BUSINESS_CURRENCY
      if ( this.entity.has_payment_plans ) {
        text =  this.$t('priceFrom') + ' ' + text
      }
      return text
    },
  },
  methods : {
    ...mapActions('courses', [
      'GET_COURSE_PUBLIC' ,
      'CHECK_STUDENT_ENROLLMENT',
      'GET_COURSE_FULL',
      'ENROLL_ON_COURSE' ,
      'GET_ENROLLMENT_WAVES_STUDENT'
    ]),
    ...mapActions('market', [
      'GET_BUSINESS_MARKET'
    ]),



    // technical
    async initPage(){

      let course
      let courseUuid = this.courseUuid || this.course.uuid
      if ( !this.loggedIn) {
        course = await this.GET_COURSE_PUBLIC(courseUuid)
      } else {
        course = await this.GET_COURSE_FULL(courseUuid)
      }

      if (this.course.uuid) {
        this.entity = this.COPY(course)
        this.entity.description = this.course.description
        this.entity.short_description = this.course.short_description
        this.entity.img = this.course.img
        this.entity.features = this.course.features
        this.entity.name = this.course.name

        this.entity = this.COPY(this.entity)
      } else {
        this.entity = course
      }

      this.business = await this.GET_BUSINESS_MARKET(this.entity.business_alias)
    },
  },
  mounted() {
    this.initPage()
  }

}
</script>

<style scoped>

</style>